.footer {
    position: relative;
    background: #fff;
    width: 100%;
    box-shadow: rgba(33, 33, 33, 0.1) 0px 8px 14px;
}

.language-selector {
    .dropdown-icon {
        transform: translateY(-4px);
    }
}
