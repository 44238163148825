.panel {
    &-title {
        cursor: pointer;

        display: flex;
        align-items: center;
    }

    &-body {
        overflow: hidden;
    }
}
