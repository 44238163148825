.header__auth {
    position: relative;
    margin-left: auto;
    display: flex;

    .link__signin {
        display: flex;
        align-items: center;
        margin-right: 0px;
        font-size: 1em;
        cursor: pointer;
        transition: 0.2s ease;
        border-radius: 15px;
        height: var(--header-item-height);
        padding: 0px 25px;
        color: var(--text-color-primary);
        white-space: nowrap;

        &:hover {
            background: rgba(14, 19, 24, 0.05);
        }
    }

    .header__toggle {
        display: flex;
        align-items: center;
        font-size: 1em;
        cursor: pointer;
        z-index: 1;
        justify-content: center;
        margin: 0;
        transition: background 0.2s ease-in-out;
        border-radius: 15px;

        height: var(--header-height);
        margin-left: 20px;
        margin-right: 20px;

        padding: 5px;

        width: var(--header-item-height);
        height: var(--header-item-height);
        border-radius: 50%;
        box-sizing: border-box;

        &:hover {
            background: rgba(14, 19, 24, 0.05);
        }

        .profile__icon {
            position: relative;
            height: 100%;
            width: 100%;
            border-radius: 50%;

            box-sizing: border-box;

            &.premium {
                box-shadow: 0 0 0px 1px var(--primary-blue);
                padding: 1px;

                svg {
                    display: block;
                    color: var(--premium-main-color);
                    position: absolute;
                    bottom: -2px;
                    right: -10px;
                    border-radius: 50%;
                    padding: 2px;
                    font-size: 0.9em;
                }
            }

            img {
                height: 100%;
                width: 100%;
                border-radius: 50%;
            }

            svg {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 810px) {
    .header__auth {
        .header__toggle {
            &:hover {
                background: transparent;
            }

            .profile__icon {
                position: relative;
                height: 30px;
                width: 30px;
                border-radius: 50%;

                img {
                    height: 100%;
                    width: 100%;
                    border-radius: 50%;
                }

                &.premium svg {
                    font-size: 0.8em;
                }
            }
        }
    }
}
