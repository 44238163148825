@mixin horizontal-scrollbar($size: 4, $hover-size: 8) {
    & > div:nth-child(2) {
        height: #{$size}px !important;
        z-index: 1;

        div {
            border-radius: calc(#{$size}px / 2) !important;
            height: #{$size}px !important;
            transition: 0.2s ease-in-out;
            transition-property: height, margin-top, border-radius;

            &:hover,
            &:active {
                height: #{$hover-size}px !important;
                margin-top: calc(#{-$hover-size + $size}px);
                border-radius: calc(#{$hover-size}px / 2) !important;
            }
        }
    }
}

@mixin vertical-scrollbar($size: 4, $hover-size: 8) {
    & > div:nth-child(3) {
        width: calc(#{$size}px) !important;
        z-index: 1;

        div {
            border-radius: calc(#{$size}px / 2) !important;
            width: #{$size}px !important;
            transition: 0.2s ease-in-out;
            transition-property: width, margin-left, border-radius;

            &:hover,
            &:active {
                width: #{$hover-size}px !important;
                margin-left: #{-$hover-size + $size}px;
                border-radius: calc(#{$hover-size}px / 2) !important;
            }
        }
    }
}
