.sql-code {
    font-family: 'Fira Mono', Arial, monospace !important;
    margin: 0;
}

.sql-code-line {
    background: #f2f2f2;
    padding: 1px 5px;
    border-radius: var(--border-radius-s);
    font-size: 0.9em;
    font-family: var(--font-500) !important;

    &--blue-color {
        color: var(--primary-blue);
    }
}

.sql-code-block-wrapper {
    position: relative;
    background: #f2f2f2;
    border-radius: var(--border-radius-m);

    &:hover {
        .env {
            opacity: 1;
        }
    }

    .sql-code-block-toolbar {
        position: absolute;
        top: 5px;
        right: 5px;
        display: flex;
        align-items: center;
    }

    .sql-code-block {
        padding: var(--indent-m) var(--indent-xl);
        border-radius: var(--border-radius-m);
        overflow-x: auto;

        font-size: 16px;
        line-height: calc(16px * 1.5);

        .refractor {
            margin: 0px;
        }
    }

    div.env {
        transition: opacity 0.2s ease-in-out;
        background: rgba(0, 0, 0, 0.05);
        display: block;
        padding: 6px;
        line-height: 1;
        border-radius: var(--border-radius-s);
        margin-right: 5px;
        max-height: 13px;
        font-family: 'Fira Mono', Arial, monospace !important;
        font-size: 13px;
        color: #666;
        opacity: 0;
    }
}
