.no-data {
    background: #f0f0f0;
    padding: 10px 10px;
    border-radius: var(--border-radius-m);
    font-weight: 400;
    text-align: center;
}

.result-table {
    table {
        border: none !important;
        width: 100%;
        position: relative;
        border-collapse: unset;
        border-spacing: 0px;

        th,
        td {
            border: none !important;
            padding: 4px 10px !important;
            box-sizing: border-box;
            height: 38px;
            white-space: nowrap;
        }

        th {
            background: #f0f0f0;
            font-weight: 400;
            text-align: left;
            box-shadow: inset 0 -1px 0 0 #ddd;
            position: sticky;
            top: 0;
        }

        tr {
            transition: 0.1s ease background;

            &:nth-child(2n) td {
                background-color: #f5f5f5;
            }

            &:nth-child(2n + 1) td {
                background-color: var(--light-gray);
            }

            &:hover td {
                background: var(--border-color);
            }

            td {
                text-align: left !important;
                white-space: nowrap;
            }

            .table-row-number {
                box-shadow: inset -1px 0px 0 0 #ddd;

                text-align: right !important;
            }

            th.table-row-number {
                box-shadow: inset -1px 0px 0 0 #ddd, inset 0 -1px 0 0 #ddd;
            }

            td.table-row-number {
                background-color: #f2f2f2;

                width: 0.1%;
                white-space: nowrap;
            }
        }
    }

    &.line-break {
        table {
            td {
                white-space: pre-line;
            }
        }
    }
}
