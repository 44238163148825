.header_dropdown_items {
    position: fixed;
}

.header {
    --header-item-height: 46px;

    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 1000;
    font-size: 1.05em;

    .content {
        display: flex;
        padding: 0px 40px;
        max-width: var(--page-width-l);
        margin: auto;
        height: 65px;

        &--fullwidth {
            max-width: 100%;
            padding: 0px 20px;
        }

        .header__logo {
            display: flex;
            align-items: center;
            position: relative;

            .logo-link img {
                width: 160px;
                display: block;
            }
        }

        .header__full-menu {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .header__nav {
                display: flex;
                align-items: center;
                padding-left: 35px;
                width: 100%;

                .nav-links {
                    display: flex;
                    align-content: center;
                    min-width: 100%;
                    height: 100%;
                    align-items: center;
                    gap: var(--indent-s);
                }
            }

            .nav-item {
                position: relative;
                display: flex;
                align-items: center;
                margin-right: 0px;
                cursor: pointer;
                color: var(--text-color-primary);
                padding: 0px 25px;
                text-decoration: none;
                letter-spacing: 0.4px;
                border-radius: 15px;
                height: var(--header-item-height);
                opacity: 0.85;

                transition: 0.2s ease;

                &.rc-dropdown-open,
                &:hover {
                    opacity: 1;
                    background: rgb(30 30 30 / 7%);
                }
            }

            .premium-nav-item {
                color: var(--premium-main-color);

                &__text {
                    margin-left: var(--indent-s);
                    color: var(--text-color-primary);
                }
            }
        }

        .header__mobile-menu {
            display: none;
            margin-right: 16px;

            .toggle {
                cursor: pointer;
                font-size: 1.5em;
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                color: var(--primary-blue);

                svg {
                    margin: 0 auto;
                }
            }
        }

        .header__rightbar {
            display: flex;
            align-items: center;
            gap: var(--indent-s);
        }
    }
}

.header-main-menu {
    .nav-links {
        margin-top: 14px;
        box-shadow: 0 8px 14px rgba(33, 33, 33, 0.1);

        .nav-item {
            &:hover {
                background: rgba(14, 19, 24, 0.05);
            }

            div,
            a {
                display: block;
                padding: 15px 20px;
                margin: 0 auto;
                font-size: 1.1rem;
                font-family: var(--font-500);
                text-decoration: none;
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .header__rightbar .search {
        display: none;
    }
}

@media screen and (max-width: 960px) {
    .header {
        .content {
            padding: 0;
            display: flex;

            .header__full-menu {
                .header__rightbar {
                    margin-left: auto;
                    display: flex;
                    align-items: center;

                    .header-rating {
                        img {
                            height: 25px;
                            width: 25px;
                            padding-right: 5px;
                        }

                        span {
                            display: none;
                        }
                    }
                }

                .premium-nav-item {
                    display: none;
                }
            }

            .header__logo {
                flex-grow: 1;
            }

            .header__nav {
                display: none !important;
            }

            .header__mobile-menu {
                display: flex;
                align-items: center;

                .toggle {
                    padding-left: 20px;
                }
            }
        }
    }

    .header-main-menu {
        .nav-links {
            display: none;
        }
    }

    .header_dropdown_items {
        width: 100%;
    }
}
